import React from 'react';
import { withPrefix } from 'gatsby';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withRoot from '../withRoot';

import Layout from '../components/Layout';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import link from '../theme/styles/link';
import footerChevron from '../theme/styles/footerChevron';

const styles = () => ({
  ...container,
  ...section,
  ...link,
  ...footerChevron,
});

const Terms = ({ classes, location }) => (
  <Layout location={location} title="Terms and Conditions">
    <div className={classNames(classes.container, classes.section)}>
      <Typography component="h1" variant="h1">
        Terms of Use
      </Typography>
      <Typography variant="h2">Welcome to movetrac&reg;, by TWO MEN AND A TRUCK&reg;</Typography>
      <Typography variant="body1" gutterBottom>
        These terms and conditions outline the rules and regulations for the use
        of TWO MEN AND A TRUCK's movetrac&reg; Website and app.
      </Typography>
      <Typography variant="body1" gutterBottom>
        TWO MEN AND A TRUCK is located at:
      </Typography>
      <Typography component="address" variant="body1" gutterBottom>
        3400 Belle Chase Way<br />
		Lansing, Michigan 48911<br />
		United States of America
      </Typography>
      <Typography variant="body1" gutterBottom>
        By accessing this website we assume you accept these terms and
        conditions in full. Do not continue to use this site and app if you do not accept all of the terms and
        conditions stated on this page.
      </Typography>
      <Typography variant="body1" gutterBottom>
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and any or all Agreements: “Client”,
        “You” and “Your” refers to you, the person accessing this website and
        accepting the Company’s terms and conditions. “The Company”,
        “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”,
        “Parties”, or “Us”, refers to both the Client and ourselves, or either
        the Client or ourselves. All terms refer to the offer, acceptance and
        consideration of payment necessary to undertake the process of our
        assistance to the Client in the most appropriate manner, whether by
        formal meetings of a fixed duration, or any other means, for the express
        purpose of meeting the Client’s needs in respect of provision of the
        Company’s stated services/products, in accordance with and subject to,
        prevailing law of United States. Any use of the above terminology or
        other words in the singular, plural, capitalisation and/or he/she or
        they, are taken as interchangeable and therefore as referring to same.
      </Typography>
      <Typography variant="h2">License</Typography>
      <Typography variant="body1" gutterBottom>
        Unless otherwise stated, TWO MEN AND A TRUCK and/or it’s licensors own
        the intellectual property rights for all material on movetrac&reg;. All intellectual property rights are reserved. You may view
        and/or print pages from https://movetrac.com for your own personal use
        subject to restrictions set in these terms and conditions.
      </Typography>
      <Typography variant="body1" gutterBottom>
        You must not:
      </Typography>
      <Typography component="ol" variant="body1">
        <li>Republish material from https://movetrac.com</li>
        <li>Sell, rent or sub-license material from https://movetrac.com</li>
        <li>Reproduce, duplicate or copy material from https://movetrac.com</li>
        <li>
          Redistribute content from movetrac&reg; (unless content is
          specifically made for redistribution).
        </li>
      </Typography>
      <Typography variant="h2">Hyperlinking to our Content</Typography>
      <Typography variant="body1" gutterBottom>
        The following organizations may link to our Web site without prior
        written approval:
        <Typography component="ol" variant="body1">
          <li>Government agencies;</li>
          <li>Search engines;</li>
          <li>News organizations;</li>
          <li>
            Online directory distributors when they list us in the directory may
            link to our Web site in the same manner as they hyperlink to the Web
            sites of other listed businesses; and
          </li>
        </Typography>
      </Typography>
      <Typography variant="body1" gutterBottom>
        These organizations may link to our home page, to publications or to
        other Web site information so long as the link: (a) is not in any way
        misleading; (b) does not falsely imply sponsorship, endorsement or
        approval of the linking party and its products or services; and (c) fits
        within the context of the linking party's site.
      </Typography>
      <Typography variant="body1" gutterBottom>
        We may consider and approve in our sole discretion other link requests
        from the following types of organizations:
      </Typography>
      <Typography component="ol" variant="body1">
        <li>
          Commonly-known consumer and/or business information sources such as
          Chambers of Commerce, American Automobile Association, AARP and
          Consumers Union;
        </li>
        <li>
          Associations or other groups representing charities, including charity
          giving sites,
        </li>
        <li>Online directory distributors;</li>
        <li>
          Accounting, law and consulting firms whose primary clients are
          businesses; and
        </li>
        <li>Educational institutions and trade associations.</li>
      </Typography>
      <Typography variant="body1" gutterBottom>
        We will approve link requests from these organizations if we determine
        that: (a) the link would not reflect unfavorably on us or our accredited
        businesses (for example, trade associations or other organizations
        representing inherently suspect types of business, such as work-at-home
        opportunities, shall not be allowed to link); (b)the organization does
        not have an unsatisfactory record with us; and (c) where the link is in
        the context of general resource information or is otherwise consistent
        with editorial content in a newsletter or similar product furthering the
        mission of the organization.
      </Typography>

      <Typography variant="body1" gutterBottom>
        These organizations may link to our home page, to publications or to
        other Web site information so long as the link: (a) is not in any way
        misleading; (b) does not falsely imply sponsorship, endorsement or
        approval of the linking party and it products or services; and (c) fits
        within the context of the linking party's site.
      </Typography>

      <Typography variant="body1" gutterBottom>
        If you are among the organizations listed in paragraph 2 above and are
        interested in linking to our website, you must notify us by sending an
        e-mail to{' '}
        <a
          href="mailto:dataadmin@twomen.com"
          title="movetrac request to hyperlink"
          className={classes.link}
        >
          dataadmin@twomen.com
        </a>
        . Please include your name, your organization name, contact information
        (such as a phone number and/or e-mail address) as well as the URL of
        your site, a list of any URLs from which you intend to link to our Web
        site, and a list of the URL(s) on our site to which you would like to
        link. Allow 2-3 weeks for a response.
      </Typography>

      <Typography variant="body1" gutterBottom>
        Approved organizations may hyperlink to our Web site as follows:
      </Typography>

      <Typography component="ol" variant="body1">
        <li>By use of our corporate name; or</li>
        <li>
          By use of the uniform resource locator (Web address) being linked to;
          or
        </li>
        <li>
          By use of any other description of our Web site or material being
          linked to that makes sense within the context and format of content on
          the linking party's site.
        </li>
      </Typography>
      <Typography variant="body1" gutterBottom>
        No use of TWO MEN AND A TRUCK’s or movetrac&reg;'s logo or other artwork will be allowed
        for linking absent a trademark license agreement.
      </Typography>
      <Typography variant="h2">Reservation of Rights</Typography>
      <Typography variant="body1" gutterBottom>
        We reserve the right at any time and in its sole discretion to request
        that you remove all links or any particular link to our Web site. You
        agree to immediately remove all links to our Web site upon such request.
        We also reserve the right to amend these terms and conditions and its
        linking policy at any time. By continuing to link to our Web site, you
        agree to be bound to and abide by these linking terms and conditions.
      </Typography>
      <Typography variant="h2">Removal of links from our website</Typography>
      <Typography variant="body1" gutterBottom>
        If you find any link on our Web site or any linked web site
        objectionable for any reason, you may contact us about this. We will
        consider requests to remove links but will have no obligation to do so
        or to respond directly to you.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Whilst we endeavour to ensure that the information on this website is
        correct, we do not warrant its completeness or accuracy; nor do we
        commit to ensuring that the website remains available or that the
        material on the website is kept up to date.
      </Typography>
      <Typography variant="h2">Content Liability</Typography>
      <Typography variant="body1" gutterBottom>
        We shall have no responsibility or liability for any content appearing
        on your Web site. You agree to indemnify and defend us against all
        claims arising out of or based upon your Website. No link(s) may appear
        on any page on your Web site or within any context containing content or
        materials that may be interpreted as libelous, obscene or criminal, or
        which infringes, otherwise violates, or advocates the infringement or
        other violation of, any third party rights.
      </Typography>
      <Typography variant="h2">Disclaimer</Typography>
      <Typography variant="body1" gutterBottom>
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties and conditions relating to our website and
        the use of this website (including, without limitation, any warranties
        implied by law in respect of satisfactory quality, fitness for purpose
        and/or the use of reasonable care and skill). Nothing in this disclaimer
        will:
      </Typography>
      <Typography component="ol" variant="body1">
        <li>
          Limit or exclude our or your liability for death or personal injury
          resulting from negligence;
        </li>
        <li>
          Limit or exclude our or your liability for fraud or fraudulent
          misrepresentation;
        </li>
        <li>
          Limit any of our or your liabilities in any way that is not permitted
          under applicable law; or
        </li>
        <li>
          Exclude any of our or your liabilities that may not be excluded under
          applicable law.
        </li>
      </Typography>
      <Typography variant="body1" gutterBottom>
        The limitations and exclusions of liability set out in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer
        or in relation to the subject matter of this disclaimer, including
        liabilities arising in contract, in tort (including negligence) and for
        breach of statutory duty.
      </Typography>
      <Typography variant="body1" gutterBottom>
        To the extent that the website and the information and services on the
        website are provided free of charge, we will not be liable for any loss
        or damage of any nature.
      </Typography>
    </div>
    <div className={classes.footerChevronContainer}>
      <img
        src={withPrefix('/img/bottom--alt.svg')}
        alt=""
        className={classes.footerChevron}
      />
    </div>
  </Layout>
);

export default withRoot(withStyles(styles)(Terms));
