import { theme } from '../theme';
import { transformTransition } from '../theme';

export default {
  link: {
    color: theme.palette.primary.main,
    transition: 'color 200ms ease',
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -2,
      left: 0,
      right: 0,
      margin: 'auto',
      width: '100%',
      height: 2,
      transform: 'scaleX(0)',
      background: theme.palette.common.black,
      transition: transformTransition,
    },

    '&:hover, &:focus': {
      color: theme.palette.common.black,
      textDecoration: 'none',
      outline: 'none',

      '&:after': {
        transform: 'scaleX(1)',
      },
    },
  },
};
