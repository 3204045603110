import { theme } from '../theme';

export default {
  footerChevron: {
    display: 'block',
    width: '104%',
    maxWidth: '104%',
    position: 'absolute',
    left: '-2%',
    right: '-2%',
    marginTop: -2,
    zIndex: 5,
  },
  footerChevronContainer: {
    position: 'absolute',
    overflow: 'hidden',
    width: '100%',
    paddingBottom: '20%',
  },
};
